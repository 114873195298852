<template>
  <div>
    <Header titleurl="secondhouse"></Header>
    <div>
      <div class="containers">
        <div class="rows">
          <div class="search">
            <el-input type="text" src placeholder="请输入二手房关键字" v-model="param.kw"></el-input>
            <img src="../../assets/img/seach.png" />
            <el-button class="searchp" @click="searchhouse">搜房子</el-button>
            <span><el-button type="danger" plain class="second_position" @click="releasesecondhouse">发布房源</el-button></span>
          </div> 
        </div>  
        <div class="rows">
          <router-link to="/">
            <span>
              <div class="position">牛豆豆</div>
            </span>
          </router-link>
          <span class="position_right">&gt;</span>
          <el-select
            class="searchjinyingu"
            v-model="searchjinyingu"
            @change="jobrecruit()"
            placeholder="请选择"
            style="width:12%;"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="rowsinput" v-for="(item,index) in screendata" :key="index">
          <div v-if="item.showMode === 0"  class="position">{{item.screenName}}:</div>
          <div class="every">
            <div class="everything">
              <div v-if="item.isMultiple != 1">
                <div v-if="item.showMode === 0" style="width:1100px;">
                  <el-radio-group v-model="item.countyCode" v-for="it in item.itemList"  :key="it.id" @change="single(item.id,it.id)" style="float:left;line-height:25px">
                    <el-radio :label="it.id" name="checkemt" >{{it.itemName}}</el-radio>
                  </el-radio-group>
                  <span v-if="item.allowCustom ===1" class="spans">
                    <el-input v-model.number="item.itemMinValue" class="inputnum" />
                    <div class="line">-</div>
                    <el-input v-model.number="item.itemMaxValue" class="inputnum" /><div class="line">{{item.customUnit}}</div>
                    <el-button class="buttonnum" @click="submitCustomunit(item)">确定</el-button>
                  </span>
                </div>  
                <div v-if="item.showMode === 1" class="rowselect">
                  <el-select v-model="item.itemList.id" size="small"  :placeholder="item.screenName" @change="single(item.id,item.itemList.id)">
                    <el-option
                      v-for="its in item.itemList"
                      :key="its.id"
                      :label="its.itemName"
                      :value="its.id">
                    </el-option>
                  </el-select>
                </div>    
              </div>
              <div v-if="item.isMultiple === 1">
                <div v-if="item.showMode === 0" style="width:1100px;">
                  <el-checkbox :checked="itss.isCheck" v-for="itss in item.itemList" :key="itss.id" @change="multiple(item.id,itss)"  style="float:left;line-height:25px">{{itss.itemName}}
                  </el-checkbox>
                  <span v-if="item.allowCustom ===1" class="spans">
                    <el-input v-model.number="item.itemMinValue" class="inputnum" />
                    <div class="line">-</div>
                    <el-input v-model.number="item.itemMaxValue" class="inputnum" /><div class="line">{{item.customUnit}}</div>
                    <el-button class="buttonnum" @click="submitCustomunit(item)">确定</el-button>
                  </span>
                </div>
                <div v-if="item.showMode === 1"  class="rowselect">
                  <el-select v-model="item.itemList.id" class="muchselect" size="small" :placeholder="item.screenName" clearable multiple  @change="multipleselect(item.id,item.itemList.id)">
                    <el-option
                      v-for="its in item.itemList"
                      :key="its.id"
                      :label="its.itemName"
                      :value="its.id">
                    </el-option>
                  </el-select>
                </div>    
              </div>
           
            </div>
          </div>
        </div>


        <div class="houseType">
          <div class="result">
            共找到&nbsp;<span>{{result.totalCount}}</span>&nbsp;套二手房
          </div>
        </div>
        <div class="secNav">
          <div class="switchpage">
          </div>
          <div class="sonpage">
            <div class="houseMsg" v-if="houselist.length > 0">
              <div  class="houseinrto" v-for="item in houselist" :key="item.id" @click="housedetail(item.id)">
                <img class="sechouse" v-if="item.houseImg!=''" :src="item.houseImg" />
                <img class="sechouse" v-if="item.houseImg===''" src="../../assets/img/sechouse1.png" />
                <div class="house_describe">
                  <h3 class="titlelength">
                    <span>{{item.title===""?item.communityName+"/"+item.roomCount+"室"+item.parlourCount+"厅"+item.bathroomCount+"卫"+"/"+item.area+'平米':item.title?item.title:""}}</span>
                    <!-- <div>稀缺好房</div> -->
                  </h3>
                  <h4 class="basic">
                    <span>{{item.roomCount}}室{{item.parlourCount}}厅{{item.bathroomCount}}卫</span>
                    <span class="standingup">|</span>
                    <span>{{item.area}}㎡</span>
                    <span class="standingup">|</span>
                    <!-- <span>南北通透</span> -->
                    <span>{{!!item.floor?item.floor+"层":''}}</span>
                  </h4>
                  <h4 class="street">
                    <h4 class="locations">
                    <span>{{item.countyName}}{{item.communityName}}</span>
                      <img
                        class="dingwei"
                        src="../../assets/img/dingwei.png"
                      />
                      {{item.address}}
                    </h4>
                  </h4>
                  <p><span>{{item.totalPrice}}</span>万</p>
                  <p>{{item.unitPrice}}元/㎡</p>
                </div>
              </div>
            </div>
            <div class="houseMsg" v-if="houselist.length === 0">
              <div class="houseMsg-nosecondhouse">暂无二手房</div>
            </div>
            <div class="pags">
              <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="param.pageSize"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :total="result.totalCount"
              ></el-pagination>
            </div>
          </div>



        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header';
import Footer from '@/components/footer';
import Utilcity from '@/utils/unilcity.js';
import { select,screen } from '@/api/secondhouse';
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      searchjinyingu:'secondhouse',
      options:[{ label: '牛豆豆二手房', value: 'secondhouse' },{label:'牛豆豆求职',value:'jobwanted'},{label:'牛豆豆招聘',value:'recruit'},{label:'牛豆豆出租房',value:'rentinghouse'}],
      houseurl: 'Home',
      param: {
        cityId: 411100,
        pageIndex: 1,
        pageSize: 10,
        kw: "",
        screenDataList: []
      },
      screendata:[],
      cityId:'410100',
      countyCode:'99987',
      houselist:[],
      result:{},
    };
  },
  mounted() {
    Utilcity.$on('cityid',(message)=>{
      this.cityId = message 
      this.param.cityId = message
      this.screen()

    })
    if(localStorage.getItem('cityid')){
      this.cityId = localStorage.getItem('cityid')
      this.param.cityId = localStorage.getItem('cityid')
    }
    if(this.$route.query.countyCode!==undefined){
      this.countyCode = parseInt(this.$route.query.countyCode)

    }
    this.screen()

  },
  watch:{
    
  },
  methods: {
    //单选条件
    single(firstid,it){
      const this_ = this
      // console.log(firstid)
      // console.log(it)
      //查找查询列表的data里面有没有存储过此类的
      let isexist=this.param.screenDataList.filter(v=>v.id===firstid).length>0
      //存储过的
      if(isexist){
        this.param.screenDataList.forEach(function(item,i){
          if(item.id===firstid){
            this_.param.screenDataList[i].itemList=[{id:it}]
          }
        })
      }else{//没有存储过的
        this.param.screenDataList.push({id:firstid,itemList:[{id:it}]})
      }
      this.selects()
    },
    //多选条件
    multiple(firstid,itss){
      const self = this
      // console.log(firstid)
      // console.log(itss)
      //让isCheck选中或者取消选中
      this.screendata.forEach(function(item,i){
        if(item.id === firstid){
          item.itemList.forEach(function(m,n){
            if(m.id === itss.id){
              self.screendata[i].itemList[n].isCheck = !self.screendata[i].itemList[n].isCheck
              // console.log(self.screendata[i].itemList[n].isCheck)
            }
          })
        }
      })
      //查找查询列表的data里面有没有存储过此类的
      let isexist=this.param.screenDataList.filter(v=>v.id===firstid).length>0
      //当选中时
      if(itss.isCheck){
        //当选中的选项存储有时
        if(isexist){
         this.param.screenDataList.forEach(function(item,i){
            if(item.id === firstid){
              // console.log("1010101010")
              self.param.screenDataList[i].itemList.push({id:itss.id})
              //  console.log("202022202")
            } 
          })
        }else{//当选中的选项没有存储时
          self.param.screenDataList.push({id:firstid,itemList:[{id:itss.id}]})
        }

      }else{//当把选中关闭时
        this.param.screenDataList.forEach(function(item,i){
          if(item.id === firstid){
              if(item.itemList.length>1){
                self.param.screenDataList[i].itemList.forEach(function(m,n){
                  if(m.id===itss.id){
                    self.param.screenDataList[i].itemList.splice(n,1)
                  }
                })
              }else{
                self.param.screenDataList.splice(i,1)
              }
          }
        })
      }
      this.selects()
    },
    multipleselect(firstid,itss){
      const self = this
      // console.log(firstid)
      // console.log(itss)
      //存储过的
      if(this.param.screenDataList.length===0){
        this.param.screenDataList.push({id:firstid,itemList:[{id:itss}]})
      }else{
       let isexist=this.param.screenDataList.filter(v=>v.id===firstid).length>0
        if(isexist){
          this.param.screenDataList.forEach(function(item,i){
            if(item.id === firstid){
              if(itss.length>0){
                self.param.screenDataList[i].itemList=[]
                itss.forEach(function(m,n){
                  self.param.screenDataList[i].itemList.push({id:m})
                })
                // console.log("3333333")
              }else{
                self.param.screenDataList.splice(i,1)
              }
            }
          })  
        }else{//没有存储过的
          self.param.screenDataList.push({id:firstid,itemList:[{id:itss[0]}]})
        }
      }
      this.selects()
    },
    //自定义
    submitCustomunit(item){
      const self = this
      // console.log(item)
      if(item.itemMinValue>item.itemMaxValue){
        this.screendata.forEach(function(o,p){
          if(o.id=item.id){
            let max = o.itemMaxValue
            o.itemMaxValue = o.itemMinValue
            o.itemMinValue = max
            console.log(item)
          }
        })
       
      }
      if(this.param.screenDataList.length===0){
        this.param.screenDataList.push({id:item.id,itemList:[{itemMinValue:item.itemMinValue,itemMaxValue:item.itemMaxValue}]})
        // console.log("9999")
      }else{
        let isexist=this.param.screenDataList.filter(v=>v.id===item.id).length>0
        if(isexist){
          this.param.screenDataList.forEach(function(it,i){
            //当查询param条件里有这一类的东西时
            if(it.id===item.id){
                //当此类定义的itemList值的长度大于1时
                if(it.itemList.length>1){
                  self.param.screenDataList[i].itemList.forEach(function(m,n){
                    //当此类总的某一个已经有这个值itemMaxValue时
                    if(m.itemMaxValue!==undefined){
                      self.param.screenDataList[i].itemList.splice(n,1)
                      //如果此类的输入框是空的
                      if(item.itemMinValue===''&&item.itemMaxValue===''){
                        // console.log("444")
                        return
                      }
                      self.param.screenDataList[i].itemList.push({itemMinValue:item.itemMinValue,itemMaxValue:item.itemMaxValue})
                      //  console.log("222")
                      return
                    }//如果到最后一个还没有找到itemMaxValue时，直接加入
                    if(n+1===self.param.screenDataList[i].itemList.length){
                      self.param.screenDataList[i].itemList.push({itemMinValue:item.itemMinValue,itemMaxValue:item.itemMaxValue})
                      return
                      // console.log("111")
                    }
                  })
                }else{//当此类定义的itemList值的长度<=1时
                  // console.log("333")
                  self.param.screenDataList.splice(i,1)
                  //如果此类的输入框是空的
                  if(item.itemMinValue===''&&item.itemMaxValue===''){
                    // console.log("444")
                    return
                  }
                  self.param.screenDataList.push({id:item.id,itemList:[{itemMinValue:item.itemMinValue,itemMaxValue:item.itemMaxValue}]})
                  return
                }
                return
            }
          })
        }else{
          //当到最后一个还没有找到次类时
          self.param.screenDataList.push({id:item.id,itemList:[{itemMinValue:item.itemMinValue,itemMaxValue:item.itemMaxValue}]})
        }
      }
      this.selects()
    },
     //筛选条件
    screen(){
      const self = this
      screen({cityId:this.cityId}).then((res) => {
          var sdata = res.data.result
          sdata.forEach(v=>{
            if(v.isMultiple!=1&&v.showMode === 0){
                v.countyCode = ''
            }
            v.itemList.forEach(function(m,n){
              if(m.id===self.countyCode){
                v.countyCode = self.countyCode
                self.param.screenDataList.push({id:v.id,itemList:[{id:m.id}]})
              }
            })
            if(v.itemList&&v.itemList.length&&v.isMultiple===1){
              v.itemList.forEach(sub=>{
                sub.isCheck = false
              })
            }
            if(v.allowCustom===1){
                v.itemMinValue = ''
                v.itemMaxValue = ''
            }
          })
          this.screendata = sdata
          this.selects()
      });
    },
    //二手房列表
    selects() {
      select(this.param).then(res => {
        this.houselist = res.data.result.list
        this.result =res.data.result
      })
    },
    //发布二手房
    releasesecondhouse(){
      this.$message({
        type:'warning',
        message:'请下载手机App发布房源'
      })
    },
    //点击二手房进入详情页
    housedetail(val){
      let routerJump=this.$router.resolve({path:'/secondhouse/housedetail',query:{id:val}})
      window.open(routerJump.href, '_blank');
    },
    //搜房子
    searchhouse(){
      this.param.pageIndex = 1
      this.selects()
    },
    //每页多少条
    handleSizeChange(val){
      this.param.pageSize = val
      this.selects()
    },
    //当前页
    handleCurrentChange(val){
      this.param.pageIndex = val
      this.selects()
    },
    //牛豆豆__
    jobrecruit() {
      this.$router.push('/' + this.searchjinyingu);
    },
  },
};
</script>
<style scoped>
.containers {
  width: 1140px;
  margin:0 auto;
  margin-bottom: 30px;
}
.rows {
  display: inline-block;
  width: 1140px;
  margin: 0 auto;
  line-height: 30px;
}
.rowsinput{
  display: inline-block;
  margin: 0 auto;
  margin-top: 10px;
  line-height: 25px;
}
.search {
  position: relative;
  margin-left: 350px;
}
.search .el-input {
  width: 400px;
  font-weight: 100;
  position: absolute;
  top: 16px;
  font-size: 12px;
}
.search >>> .el-input .el-input__inner {
  padding: 0 40px;
  height: 35px;
  border-radius: 0%;
}
.search img {
  width: 20px;
  height: 20px;
  position: relative;
  top: 33px;
  left: 10px;
}
.search .el-button {
  background-color: #e8e8e8;
  color: #757777;
  height: 35px;
  border-radius: 0px;
  position: relative;
  left: 380px;
  top: 27.5px;
}
.rowsinput .position{
  margin-right: 20px;
  color: #666666;
  float: left;
}
.rowsinput>>> .el-radio__label,.rowsinput>>> .el-checkbox__label{
  font-size: 12px;
}
.rowsinput>>> .el-radio__inner,.rowsinput>>> .el-checkbox__inner{
  width: 12px;
  height: 12px;
  display: none;
}
.rowsinput>>> .el-radio__input.is-checked+.el-radio__label ,.rowsinput>>> .el-checkbox__input.is-checked+.el-checkbox__label{
  color:#ff552e;
}
.rowsinput>>> .el-radio__input.is-checked .el-radio__inner,.rowsinput>>> .el-checkbox__input.is-checked .el-checkbox__inner{
  background: #ff552e;
  border-color: #ff552e;
}
.rows .position_right {
  margin: 0 5px;
}
.rows .searchjinyingu {
  display: inline-block;
}
.rows >>> .searchjinyingu .el-input__inner,
.rows >>> .searchjinyingu i {
  height: 30px;
  line-height: 30px;
}
.rows  .second_position {
  color: #ee5500;
  background-color: #fff6ee;
  border: 2px solid #ee5500;
  margin-left: 230px;
}
.rowsinput .every span {
  color: black;
  margin-right: 12px;
  float: left;
  cursor: pointer;
}
.rowsinput .every {
  max-width: 1000px;
  min-width: 120px;
  float: left;
  box-sizing: border-box;
}
.rows button,.rowsinput button{
  height: 28px;
  border-radius: 0px;
  padding: 0px 6px;
}
.rows div,.rows span 
{
  display: inline;
  position: relative;
  font-size: 12px;
}
.rowsinput div{
  display: inline-block;
  position: relative;
  font-size: 12px;
}
.rowsinput .everything .el-checkbox {
  margin-right:15px;
}
.rowsinput .everything >>> .el-radio__label,.rowsinput .everything >>> .el-checkbox__label{
  padding-left: 1px;
}
.rowsinput .inputnum {
  height: 20px;
  line-height: 20px;
  width: 50px;
}
.rowsinput >>>.inputnum .el-input__inner {
  height: 20px;
  width: 50px;
  padding: 0 5px;
}
.rowsinput>>> input::-webkit-outer-spin-button,>>> .rows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.rowsinput input[type='number'] {
  -moz-appearance: textfield;
}

.rowsinput .buttonnum {
  height: 20px;
  font-size: 12px;
  padding: 0px 8px;
}
.rowsinput .el-radio-group label,.rows .el-checkbox-group label{
  margin-right: 15px;
}
.rowsinput .everything .rowselect {
  width: 120px!important;
  margin-right: 5px;
}
.rowsinput .everything .rowselect >>>.el-select{
  width: 120px!important;
}
.rowsinput .everything .rowselect >>> .el-select .el-input{
  width: 120px!important;
}
.rowsinput .everything >>> .muchselect .el-select__tags>span{
  display: flex!important;
}
.rowsinput .line {
  margin: 0 4px;
  line-height: 20px;
}
.imgbold {
  width: 15px;
  height: 15px;
}
.houseType .result {
  width: 308px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}
.houseType .result span {
  color: rgb(0 174 102);
  font-weight: bold;
  font-size: 20px;
}
.secNav {
  position: relative;
  width: 940px;
  min-height: 500px;
}

.secNav .switchpage {
  width: 1140px;
  height: 10px;
  border-bottom: 1.5px solid rgb(0 174 102);
}

.secNav .switchpage div {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  padding: 0 10px;
  height: 40px;
  color: black;
  margin-top: 27px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
}
.secNav .switchpage .active {
  background-color: rgb(0 174 102);
  color: white !important;
}

/********************/ 


.sonpage .checkbox{
  margin-left: 10px;
  margin-top: 15px;
}
.houseMsg{
  width: 1140px;
  min-height: 200px;
  /* border: 1px solid #0000FF; */
  padding-left: 10px;
  margin-top: 20px;
  position: relative;
}

.houseMsg .houseinrto{
  clear: both;
  height: 191px;
  margin-bottom: 30px;
  margin-top: 10px;
  cursor: pointer;
}
.houseMsg-nosecondhouse{
  font-size: 14px;
  line-height: 60px;
  margin-left: 450px;
  color:#909399;
}
.houseMsg .houseinrto .sechouse{
  float: left;
  width: 286px;
  height: 189px;
}
.houseMsg .houseinrto .house_describe{
  width:680px;
  height: 221px;
  float: left;
  line-height: 30px;
  position: relative;
}
.houseMsg .houseinrto .house_describe .titlelength{
  width: 500px;
  overflow: hidden;/*超出部分隐藏*/
  white-space: nowrap;/*不换行*/
  text-overflow:ellipsis;/*超出部分省略号显示*/
}
.houseMsg .houseinrto .house_describe .titlelength span{
  width: 500px!important;
}
.houseMsg .houseinrto .house_describe .basic .standingup{
 color:#BEBEBE;
}
.houseMsg .houseinrto .house_describe p:nth-of-type(1){
  width: 200px;
  height: 40px;
  position: absolute;
  top: 60px;
  right: 30px;
  color:rgb(255 103 103);
  text-align: end;
  font-size: 18px;
}
.houseMsg .houseinrto .house_describe p:nth-of-type(2){
  width: 200px;
  height: 40px;
  position: absolute;
  top: 110px;
  right: 30px;
  color:gray;
  text-align: end;
  font-size: 18px;
}
.houseMsg .houseinrto .house_describe p:nth-of-type(1) span{
  font-size: 35px;
  font-weight: bold;
}
.houseMsg .houseinrto .house_describe>h3{
  padding-top:10px ;
}
.houseMsg .houseinrto .house_describe>h3 span{
  font-weight: 600;
  font-size: 22px;
  margin-left: 10px;
  margin-bottom: 20px;
}
.houseMsg .houseinrto .house_describe>h3 div{
  display: inline;
  border: 2px solid rgb(255 111 111);
  font-weight: 600;
  font-size: 25px;
  margin-left: 16px;
  color: rgb(255 111 111);
  padding:  2px 8px;
  font-size: 17px
}
.houseMsg .houseinrto .house_describe>h4{
  margin: 10px 0 15px 10px;
}
.houseMsg .houseinrto .house_describe>h4 span{
  font-weight: 600;
  margin-right: 20px;
  font-size: 14px;
}
.houseMsg .houseinrto .house_describe .locations span{
  color:#2c3e50;
}
.houseMsg .houseinrto .house_describe .locations{
  color: gray;
  font-weight: 400;
}
.houseMsg .houseinrto .house_describe .tagger span{
  background-color: rgb(239 250 247);
  background-color: rgb(239 250 247);
  display: inline-block;
  color: rgb(157 214 198);
  width: 100px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-weight: 400;
  margin-right: 10px;
}
.houseMsg .houseinrto .house_describe .tagger .active{
  background-color: rgb(0 171 132);
  display: inline-block;
  color: white;
  width: 100px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-weight: 400;
  margin-right: 10px;
}
.houseMsg .dingwei{
  width: 20px;
  margin-bottom: -5px;
}
.tagger{
  position: absolute;
  top: 140px;	
}
.pags{
    clear: both;
    width: 550px; 
    height: 40px;
    margin-left: 300px;
}
</style>