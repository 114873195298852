import http from '@/utils/http'

// 二手房列表
export const select = (params) =>{
    return http.post('/house/secondlist',params)
}
// 获取二手房筛选条件
export const screen = (params) =>{
    return http.get('/house/secondscreen',params)
}
export const housesecondhotpushlist = (params) =>{
    return http.post('/house/secondhotpushlist',params)
}
export const housesecondlist = (params) =>{
    return http.post('/house/secondlist',params)
}